$('[data-toggle="video"]').click(function(event) {
	event.preventDefault();

	var modal = document.createElement('div');
	var video = $(this).find('template').html();

	var template = '<div class="modal-dialog">'+
		'<div class="modal-content"><div class="embed-responsive embed-responsive-16by9">'+video+'</div></div>'+
	'</div>';

	$(modal).addClass('modal modal-amplia-video');
	$(modal).attr('id', 'amplia-video');
	$(modal).html(template);
	$(modal).modal('show');

	$(modal).on('hide.bs.modal', function(){
		$(this).remove();
	});
});