(function(){
	var jaVerificou = false;
	function verifyTopPosition(){
		var wt = $(window).scrollTop();
		var base = $('.topo .faixa-azul').outerHeight();
		var mobileControls = $('.mobile-controls');

		if(mobileControls.css('display') == 'flex'){
			$('.topo').css('height', $('.topo').outerHeight());

			if(wt >= base){
				mobileControls.addClass('fx');
			}else{
				mobileControls.removeClass('fx');
			}
		};
	}

	$(window).scroll(function(){
		if(jaVerificou) return;

		setTimeout(function(){
			jaVerificou = false;
		},100);

		verifyTopPosition();
	});

	return verifyTopPosition();
})();