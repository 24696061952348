function startOwlCarousel(target, options){
	if($(target).children().length > 1){
		$(target).owlCarousel(options)
	}else{
		$(target).addClass('owl-loaded');
	}
}

startOwlCarousel('#albumComunidade', {
	responsive : {
		992 : {
			items: 3
		},
		768: {
			items: 2
		},
		0 : {
			items: 1
		}
	},
	nav: true,
	navText : [
		'<i class="fa fa-chevron-left"></i>',
		'<i class="fa fa-chevron-right"></i>'
	]
});