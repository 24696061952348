$('[data-toggle="menu"]').click(function(){
	var bgMenu = document.createElement('div');
	var topo = document.querySelector('.topo');

	$(bgMenu).addClass('bg-menu');

	$(bgMenu).click(function(){
		$(topo).removeClass('open');

		$(this).fadeOut(1000, function(){
			$(this).remove();
		});
	});

	$(bgMenu).fadeIn(1000);

	$(topo).addClass('open');
	topo.appendChild(bgMenu);
});
